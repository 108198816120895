<template>
  <draggable
    class="dragArea"
    tag="ul"
    :list="children"
    :group="{ name: 'g1' }"
    item-key="id"
  >
    <template #item="{ element, index }">
      <div>
        <div
          class="
            border border-gray-500
            bg-light-dark
            py-3
            mb-2
            ps-2
            fs-3
            cursor-pointer
            item-menu
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="info-menu" @click="showEdit[index] = !showEdit[index]">
              <i class="bi fs-3 bi-list me-2 text-dark"></i>
              {{ element.name }}
              <span
                class="color-gray-300 fs-4 fst-italic badge badge-light-primary"
                >{{ element.url }}</span
              >
            </div>
            <div class="action">
              <button
                type="button"
                @click="showEdit[index] = !showEdit[index]"
                class="btn btn-sm btn-primary btn-icon me-1"
              >
                <i
                  class="bi"
                  :class="[
                    showEdit[index] ? 'bi-chevron-down' : 'bi-chevron-right',
                  ]"
                ></i>
              </button>
              <!--              <button-->
              <!--                type="button"-->
              <!--                @click="addMenuItem(index)"-->
              <!--                class="btn btn-sm btn-success btn-icon me-1"-->
              <!--              >-->
              <!--                <i class="bi bi-plus"></i>-->
              <!--              </button>-->
              <button
                type="button"
                @click="removeMenuItem(index)"
                class="btn btn-sm btn-danger btn-icon me-1"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
          <div class="form p-1" v-show="showEdit[index]">
            <div class="form-group">
              <label class="fs-5">Tên hiển thị</label>

              <input
                type="text"
                v-model="element.name"
                class="form-control form-control-sm mb-1 fs-5"
              />
            </div>
            <div class="form-group">
              <label class="fs-5">Đường dẫn</label>
              <input
                type="text"
                v-model="element.url"
                class="form-control form-control-sm mb-1 fs-5"
              />
            </div>
          </div>
        </div>

        <nested-draggable :children="element.children" :level="level + 1" />
      </div>
    </template>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import { ref, onMounted } from "vue";

export default {
  props: {
    children: {
      required: true,
      type: Array,
    },
    level: {
      type: Number,
    },
  },
  components: {
    draggable,
  },

  name: "nested-draggable",
  setup(props, context) {
    const keyLevel = ref(props.level);
    const dataItems = ref(props.children);
    const showEdit = ref(
      Array.from({ length: props.children.length }, (_, i) => false)
    );

    const addMenuItem = () => {
      dataItems.value.push({
        id: dataItems.value.length + 1,
        url: "#",
        name: "Tên hiển thị",
        children: [],
      });
    };
    onMounted(async () => {
      dataItems.value = props.children;
    });
    const removeMenuItem = (index) => {
      //console.log(index);
      if (props.level === 0) context.emit("removeMenuItem", index);
      dataItems.value.splice(index, 1);
      // props.children = dataItems.value
      //console.log(dataItems.value, "dataItems");
      //console.log(props, props.children);
    };
    return {
      addMenuItem,
      showEdit,
      keyLevel,
      removeMenuItem,
      dataItems,
    };
  },
};
</script>
<style scoped>
.dragArea {
  /*min-height: 50px;*/
}
</style>
